import React, {useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
// import AirIcon from '@material-ui/icons/Air'
import { Wind } from 'react-feather'
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  upDifferenceIcon: {
    color: colors.green[900]
  },
  upDifferenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const SoilTemp = (props,{ className, ...rest }) => {
  // const [data, setData] = React.useState(props.data[0]);
  // useEffect(() => {
  //   console.log('asdf')
  //   return setData(props.data[0])
  // },[props]);
  const classes = useStyles();
  let percent = ''
  let arrow = 'up'
  let colorClass = classes.differenceValue
  if(props.data.airTemp){
    let temps = props.data.airTemp.datasets[0].data
    if(temps[0].y> temps[1].y){
      let increase = temps[0].y - temps[1].y
      percent = increase / temps[1].y * 100
      arrow = 'up'
      colorClass = classes.upDifferenceValue
    }
    else{
      let decrease = temps[1].y - temps[0].y
      percent = decrease / temps[1].y * 100
      arrow = 'down'
      colorClass = classes.differenceValue
    }
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              AIR TEMPERATURE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {props.data.airTemp.datasets[0].data[0].y.toFixed(2) + ' °F'}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Wind />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          {arrow === 'up' ? <ArrowUpwardIcon className={classes.upDifferenceIcon} /> : <ArrowDownwardIcon className={classes.differenceIcon} />}
          <Typography
            className={colorClass}
            variant="body2"
          >
            {percent.toFixed(2) + '%'}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last check-in
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

SoilTemp.propTypes = {
  className: PropTypes.string
};
// const mapStateToProps = (state) => ({
//   data: state.data.currentDevice
// })
export default connect()(SoilTemp);
