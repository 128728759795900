import { all } from 'redux-saga/effects';
// import DataSaga from './getAllDevicesSaga';
// import rawDataSaga from './getRawDataSaga';
import GFSaga from './gandFarmSaga'

export default function* rootSaga() {
  yield all([
    GFSaga()
  ]);
}
