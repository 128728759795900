import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    colors,
    makeStyles
} from '@material-ui/core';
import { useDispatch } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    avatar: {
        backgroundColor: colors.blue[600],
        height: 56,
        width: 56
    },
    tabBox: {
        whiteSpace: 'nowrap'
    },
    tab: {
        width: '35%',
        whiteSpace: 'nowrap'
    }
}));

const Device = (props, { className, ...rest }) => {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    React.useEffect(() => {
        switch (props.data.name) {
            case "Entrance":
                return setValue(0)
            case "Mid Slope":
                return setValue(1)
            case "Upper Slope":
                return setValue(2)
            default:
                return setValue(0)
        }
    }, [props.data])
    const classes = useStyles();
    let data = props.data

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <Tabs
                    className={classes.tabBox}
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Entrance" value={0} onClick={() => dispatch({ type: 'GET_DATA', payload: "806d0df2-bb3e-4f80-b408-9b45ad4fa619"})}/>
                    <Tab label="Mid Slope" value={1} onClick={() => dispatch({ type: 'GET_DATA', payload: "e80d5d46-5d6d-4bb3-9cb7-6d4dfda4305c"})}/>
                    <Tab label="Upper Slope" value={2} onClick={() => dispatch({ type: 'GET_DATA', payload: "9157c2c1-0318-4f9b-bb7f-e28275af7e8e"})}/>
                </Tabs>
            </CardContent>
        </Card>
    );
};

Device.propTypes = {
    className: PropTypes.string
};

export default Device;
