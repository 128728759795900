import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const GrandFarm = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{variant:'h2' }}
        title={"Grand Farm Overview"}
        style={{textAlign: 'center'}}
      />
      <Divider />
      <CardContent>
        <Box
          minHeight={450}
          position="relative"
        >
          <div>
          <p style={{lineHeight: '30px', textAlign: 'left'}}>
            Grand Farm, led by Emerging Prairie, (whose mission is connecting and celebrating the region’s entrepreneurial ecosystem) 
            aims to capitalize on the region’s potential in the agriculture and technology industries.

            The Grand Farm Test Site will create the farm of the future by 2025 as the global example in solving challenges to farming worldwide, 
            while unleashing vast new potential for technology for the greater good. It will do so by growing and inspiring regional businesses, 
            organizations and entrepreneurs to collaborate globally in developing technological and human solutions for farming in a new era.
          </p>
          <div style={{display: 'block', textAlign: 'center', marginTop: '50px'}}>
            <div><p>For more information about Grand Farm please visit:</p></div>
            <a href='https://grandfarm.com/' style={{textDecoration: 'underline', marginTop: ' 35px'}}>https://grandfarm.com/</a>
          </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};


export default GrandFarm;
