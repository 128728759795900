import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import TopBar from './TopBar';
import DashboardView from '../../views/grandfarm/DashboardView';
import About from '../../views/grandfarm/AboutView'
import Contact from '../../views/grandfarm/ContactView'
import { connect } from 'react-redux'
import {
  Container,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Page from "../../components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '5%',
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.mobileStepper,
    color: "#fff",
  },
  loading: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  }
}));

const GrandFarmLayout = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  if (props.data.loadingDevice === true) {
    return (
      <div className={classes.root}>
        
        <div className={classes.wrapper}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <div className={classes.contentContainer}>
              <Container className={classes.loading} maxWidth={false}>
                <Backdrop
                  className={classes.backdrop}
                  open={props.data.loadingDevice}
                >
                  <CircularProgress color="primary" thickness={5} size={100} />
                </Backdrop>
              </Container>
          </div>
        </div>
      </div>
    );
  }
  else{
  return (
        <div className={classes.root}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Route path='/app/dashboard' component={DashboardView} />
                <Route path='/app/about' component={About} />
                <Route path='/app/contact' component={Contact} />
              </div>
            </div>
          </div>
        </div>
  );
};
}

const mapStateToProps = (state) => ({
          data: state.data,
});

export default connect(mapStateToProps)(GrandFarmLayout);
