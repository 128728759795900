import React, {useEffect} from "react";
import {
  Container,
  Grid,
  makeStyles
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import SoilTemp from "./SoilTemp";
import SoilWVC from "./SoilVWC";
import AirTemp from "./AirTemp";
import SoilEC from "./SoilEC";
import BattChart from './charts/BattCharts'
import ECChart from './charts/ECChart'
import TempChart from './charts/TempChart'
import VWCChart from './charts/VWCChart'
import Devices from './Devices'
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.mobileStepper,
    color: "#fff",
  },
  loading: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  }
}));

const Dashboard = (props) => {
  const currentData = useSelector((state) => state.data.currentDevice[0]);
  const devices = useSelector((state) => state.data.data.deviceData)
  const [chartData, setData] = React.useState(currentData);

  React.useEffect(() => {
    setData(currentData)
  }, [currentData])

  const classes = useStyles();
  let change = (value) => {
    return setData(value)
  }
    return (
      <Page className={classes.root} title="Dashboard">

        <Container maxWidth={false}>
          <Grid container spacing={3}>
            {/* <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Devices data={chartData} change={change} />
            </Grid> */}
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <AirTemp data={chartData}/>
            </Grid>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <SoilTemp data={chartData} />
            </Grid>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <SoilWVC data={chartData}/>
            </Grid>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <SoilEC data={chartData}/>
            </Grid>
            <Grid item lg={12} md={12} xl={6} xs={12}>
              <TempChart data={chartData} />
            </Grid>
            <Grid item lg={12} md={12} xl={6} xs={12}>
              <BattChart data={chartData} /> 
            </Grid>
            <Grid item lg={12} md={12} xl={6} xs={12}>
              <ECChart data={chartData} />
            </Grid>
            <Grid item lg={12} md={12} xl={6} xs={12}>
              <VWCChart data={chartData}/>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }


export default Dashboard;
