import React from "react";
import {
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
import GrandAbout from './GFAbout';
import SigOverview from './SigOverview';
// import DougAbout from './DougAbout';
// import EdAbout from './EdAbout';
import BoxAbout from './BoxAbout'
import SigAbout from './SigAbout'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const About = (props) => {
  const classes = useStyles();
    return (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <SigOverview/>
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <BoxAbout/>
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <GrandAbout />
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <SigAbout />
            </Grid>
            {/* <Grid item lg={6} md={12} xl={6} xs={12}>
              <EdAbout/>
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              <DougAbout/>
            </Grid> */}
          </Grid>
        </Container>
      </Page>
    )
};

export default About;
