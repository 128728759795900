import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from '../src/components/GlobalStyles';
import { connect } from "react-redux";
import '../src/mixins/chartjs';
import theme from '../src/theme';
import GrandFarmLayout from '../src/layouts/GrandFarmLayout'
//import Home from '../src/views/home/home'
const App = (props) => {
  
  useEffect(() => {
    props.dispatch({type: 'GET_DATA', payload: 'e80d5d46-5d6d-4bb3-9cb7-6d4dfda4305c'})
  },[]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {window.location.pathname === "/" ? <Redirect to={"/app/dashboard"}/> : ''}
      <Route path='/app' component={GrandFarmLayout}/>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  data: state.deviceList
})
export default connect(mapStateToProps)(App);