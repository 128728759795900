import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    Modal,
    IconButton,
    Menu,
    MenuItem,
    Button,
    withStyles,
    Dialog,
    Typography
} from '@material-ui/core';
import { connect } from 'react-redux'
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import moment from 'moment'
import { XSquare } from "react-feather";

const useStyles = makeStyles(() => ({
    root: {},
    modalCard: {
        height: '100%',
        width: '100%'
    }
}));
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '200px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const Charts = (props, { className, ...rest }) => {
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [time, setTime] = React.useState('Now');
    const [range, setRange] = React.useState('hour')
    const classes = useStyles();
    const theme = useTheme();

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeMenu = (x) => {
        setAnchorEl(null);
        setTime(x)
    }

    const handleOpen = () => {
        setRange('day')
        setTime('3-Day')
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setTime('Now')
        setRange('hour')
    }
    let now = moment(props.data.airTemp.datasets[0].data[0].x)
    let ticks = moment(props.data.airTemp.datasets[0].data[0].x).subtract(24, 'hours')
    if (time === 'All') {
        ticks = ''
    }
    else if (time === '3-Day') {
        ticks = now.subtract(3, 'days')
    }
    else if (time === 'Week') {
        ticks = now.subtract(7 , 'days')
    }
    else if (time === 'Now'){
        ticks = moment(props.data.airTemp.datasets[0].data[0].x).subtract(24, 'hours')
    }

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: true },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    offset: true,
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        min: ticks
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    type: 'time',
                    distribution: 'linear',
                    time: {
                        unit: range,
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        callback: function (value) {
                            return value + ' °F';
                        }
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return tooltipItem.yLabel.toFixed(2);
                }
            },
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            footerFontColor: theme.palette.text.secondary,
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardHeader
                action={(
                    <IconButton
                        size="medium"
                        onClick={handleOpen}>
                        <AspectRatioIcon />
                    </IconButton>
                )}
                title={'AIR TEMPERATURE'}
            />
            <Divider />
            <CardContent>
                <Box
                    height={400}
                    position="relative"
                >
                    <Line
                        data={props.data.airTemp}
                        options={options}
                        height={35}
                        width={100}
                    />
                </Box>
            </CardContent>
            <Divider />
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
            </Box>
            {/* {console.log(props.data)} */}
            <Dialog
                open={open}
                fullScreen={true}
                fullWidth
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                // style={{ justifyContent: "center", alignItems: "center", display: "flex" }}
            >
                <Card
                    style={{ height: "100%", width: "100%" }}
                >
                    <Box
                        style={{ backgroundColor: '#0e5e8a', color: 'white', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton style={{ color: 'white' }} onClick={handleClose}><XSquare/></IconButton>
                            <Typography variant='h3'>AIR TEMPERATURE</Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                color="secondary"
                                variant='outlined'
                                onClick={openMenu}>
                                {time}
                            </Button>
                            <StyledMenu
                                id='menu'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => closeMenu(time)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                }}
                            >
                                <MenuItem onClick={() => closeMenu('3-Day')}>3 Day</MenuItem>
                                <MenuItem onClick={() => closeMenu('Week')}>Week</MenuItem>
                                {/* <MenuItem onClick={() => closeMenu('2-Weeks')}>2 Weeks</MenuItem>
                                <MenuItem onClick={() => closeMenu('Month')}>Month</MenuItem> */}
                                <MenuItem onClick={() => closeMenu('All')}>All</MenuItem>
                            </StyledMenu>
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        className={classes.modalCard}
                        height={"100%"}
                    >
                        <Box
                            height='80%'
                            position="relative"
                        >
                            <Line
                                data={props.data.airTemp}
                                options={options}
                                height={1}
                                width={1}
                            />
                        </Box>
                    </Box>
                </Card>
            </Dialog>
        </Card>
    );
};
Charts.propTypes = {
    className: PropTypes.string
};
// const mapStateToProps = (state) => ({
//     data: state.data
// })

export default connect()(Charts);
