import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import OpacityIcon from '@material-ui/icons/Opacity';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.blue[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  upDifferenceIcon: {
    color: colors.green[900]
  },
  upDifferenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const VWC = (props,{ className, ...rest }) => {
  const classes = useStyles();
  let percent = 0
  let arrow = 'up'
  let colorClass = classes.differenceValue
  let percent2 = 0
  let arrow2 = 'up'
  let colorClass2 = classes.differenceValue
    let VWC = props.data.VWC.datasets[0].data
    let VWC2 = props.data.VWC.datasets[1].data
    if(VWC[0].y > VWC[1].y){
      let increase = VWC[0].y - VWC[1].y
      percent = increase / VWC[1].y * 100
      arrow = 'up'
      colorClass = classes.upDifferenceValue
    }
    else{
      let decrease = VWC[1].y - VWC[0].y
      percent = decrease / VWC[1].y * 100
      arrow = 'down'
      colorClass = classes.differenceValue
    }
    if(VWC2[0].y > VWC2[1].y){
      let increase = VWC2[0].y - VWC2[1].y
      percent2 = increase / VWC2[1].y * 100
      arrow2 = 'up'
      colorClass2 = classes.upDifferenceValue
    }
    else{
      let decrease = VWC2[1].y - VWC2[0].y
      percent2 = decrease / VWC2[1].y * 100
      arrow2 = 'down'
      colorClass2 = classes.differenceValue
    }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
    <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              VOLUMETRIC WATER CONTENT
                </Typography>
            <Grid item>
              <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '300px'}}>
                <div>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                  >
                    12″ Sensor
                </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    {props.data.VWC.datasets[0].data[0].y.toFixed(2) + ' %'}
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                  >
                    {arrow === 'up' ? <ArrowUpwardIcon className={classes.upDifferenceIcon} /> : <ArrowDownwardIcon className={classes.differenceIcon} />}
                    <Typography
                      className={colorClass}
                      variant="body2"
                    >
                      {percent.toFixed(2) + '%'}
                    </Typography>
                  </Box>
                </div>
                <div>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                  >
                    6″ Sensor
                </Typography>
                  <Typography
                    color="textPrimary"
                    variant="h3"
                  >
                    {props.data.VWC.datasets[1].data[0].y.toFixed(2) + ' %'}
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                  >
                    {arrow2 === 'up' ? <ArrowUpwardIcon className={classes.upDifferenceIcon} /> : <ArrowDownwardIcon className={classes.differenceIcon} />}
                    <Typography
                      className={colorClass2}
                      variant="body2"
                    >
                      {percent2.toFixed(2) + '%'}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                    >
                      Since last check-in
                  </Typography>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <OpacityIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

VWC.propTypes = {
  className: PropTypes.string
};

export default connect()(VWC);
