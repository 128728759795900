import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import rootReducer from './redux/reducers';
import rootSaga from './redux/sagas';

const sagaMiddleware = createSagaMiddleware();

// For Dev purposes uncomment this one and comment out the other for logger
const middlewareList = process.env.NODE_ENV === 'development' ? [sagaMiddleware, logger]
  : [sagaMiddleware];

// const middlewareList = process.env.NODE_ENV === 'development' ? [sagaMiddleware]
//   : [sagaMiddleware];

const store = createStore(

  rootReducer,
  compose(
    applyMiddleware(...middlewareList),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ),
);

sagaMiddleware.run(rootSaga);

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>

), document.getElementById('root'));

serviceWorker.unregister();
