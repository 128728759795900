import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SigAbout = (props,{ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{variant:'h2' }}
        title={"Signum Edge"}
        style={{textAlign: 'center'}}
      />
      <Divider />
      <CardContent>
        <Box
          minHeight={450}
          position="relative"
        >
          <p style={{lineHeight: '30px', textAlign: 'left'}}>
          The <b>Signum Edge</b> devices deployed on Grand Farm are solar-powered, remote-sensing devices that deliver real-time environmental data from sensors connected using the SDI-12 protocol. This device is a rapidly deployable, rugged, and low cost data collection system with a small physical footprint.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          The hardware consists of a custom designed printed circuit board (PCB), a CAT-M cellular modem, internal storage for data backup, and eight 24-bit analog input channels, plus an on-board battery monitor and temperature/barometric pressure sensor. The hardware is also capable of configuration changes and over the air updates.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          <b>Signum Edge</b> provides real-time soil temperature and moisture levels but can also be connected to virtually any analog sensor (i.e., weather stations, water level and stream gage data, water level pressure transducers, atmospheric data, humidity, etc.). Data is sent to the cloud and can be used by a wide variety of businesses and industries, helping them to make more-informed, real-time decisions.
          </p>
        </Box>
      </CardContent>
    </Card>
  );
};


export default SigAbout;
