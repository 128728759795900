import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Tabs,
  Tab
} from '@material-ui/core';
import Logo from '../../../src/components/Logo';
import GFLogo from '../../../src/components/GF-Logo'

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: '10%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#0e5e8a'
  }
}));

const TopBar = ({ className, ...rest }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  useEffect(() => {
    if(window.location.pathname === '/app/dashboard'){
      return setValue(0)
    }
    else if(window.location.pathname === '/app/about'){
      return setValue(1)
    }
    else if(window.location.pathname === '/app/contact'){
      return setValue(2)
    }
  },[]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
            <div style={{display: 'flex', width: '15%'}}>
                <Logo />
            </div>
              <Tabs
                value={value}
                // indicatorColor='secondary'
                // width='60%'
                centered
              >
              <RouterLink to='/app/dashboard' style={{color: 'white'}}>
                <Tab label='Home' onClick={() => setValue(0)} style={{opacity: '1'}}/>
              </RouterLink>
              <RouterLink to='/app/about' style={{color: 'white'}}>
                <Tab label='About' onClick={() => setValue(1)} style={{opacity: '1'}}/>
              </RouterLink>
              <RouterLink to='/app/contact' style={{color: 'white'}}>
                <Tab label='Contact' onClick={() => setValue(2)} style={{opacity: '1'}}/>
              </RouterLink>
              </Tabs>
            <div style={{display: 'flex', width: '15%'}}>
                <GFLogo/>
            </div>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
