import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SigAbout = (props,{ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{variant:'h2' }}
        title={"Signum Platform"}
        style={{textAlign: 'center'}}
      />
      <Divider />
      <CardContent>
        <Box
          minHeight={450}
          position="relative"
        >
          <p style={{lineHeight: '30px', textAlign: 'left'}}>
          The Signum platform allows full integration from the device level using secure data connections, delivering real-time, actionable data access and visualization for a variety of needs across multiple industries.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          <b>Signum Edge</b> is a line of devices which may be used either off-the-shelf or can be customized for specific customer needs. Signum Edge integrates with a wide variety of sensors which allows for collection of real-time data for multiple applications.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          <b>Signum Connect</b> is a unified data connection platform which can use either cellular or satellite data connections.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          <b>Signum Cloud</b> is a data services platform which provides access to customer data, either through API integrations for pulling data into a customer's system, or by visualization on a customer web portal or mobile app for each customer.
          </p>
        </Box>
      </CardContent>
    </Card>
  );
};


export default SigAbout;
