import React from "react";
import {
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Page from "../../../../src/components/Page";
// import SigLogo from './SigLogo';
// import AW from './AWContact';
import QED from './QEDContact'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Contact = (props) => {
  const classes = useStyles();
    return (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            {/* <Grid item lg={8} md={12} xl={12} xs={12}>
              <SigLogo/>
            </Grid> */}
            {/* <Grid item lg={8} md={12} xl={6} xs={12}>
              <AW/>
            </Grid> */}
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <QED/>
            </Grid>
          </Grid>
        </Container>
      </Page>
    )
};

export default Contact;
