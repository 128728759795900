import { combineReducers } from 'redux';

let deviceList = []
const device1 = []

const data = (state = [], action) => {
  switch (action.type) {
    case 'SET_DATA':
      return action.payload;
    default:
      return state;
  }
};

const allData = (state = [], action) => {
  switch(action.type){
    case 'ALL_DATA':
      return action.payload;
    default:
      return state;
  }
}

const deviceData = (state = [], action) => {
  switch(action.type){
    case 'DEVICE_DATA':
      return state = [...action.payload]
    default:
      return state;
  }
}

const currentDevice = (state = [], action) => {
  switch(action.type){
    case 'DEVICE':
      state = action.payload;
      return state
    default:
      return state
  }
}

const loadingDevice = (state = true, action) => {
  switch (action.type) {
    case 'IS_LOADING':
      return action.payload
    default:
      return state;
  }
}

export default combineReducers({
  data,
  allData,
  deviceData,
  currentDevice,
  loadingDevice
});
