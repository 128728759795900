import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SigAbout = (props,{ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        titleTypographyProps={{variant:'h2' }}
        title={"Signum Company Overview"}
        style={{textAlign: 'center'}}
      />
      <Divider />
      <CardContent>
        <Box
          minHeight={450}
          position="relative"
        >
          <p style={{lineHeight: '30px', textAlign: 'left'}}>
          Signum LLC is an end-to-end Internet of Things(IoT) solution provider for customers needing remote sensing hardware and the infrastructure to access and visualize their data.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          Our engineering staff has the capability to design custom hardware for specific customer applications as well as designing a database and visualization system for data collection which is tightly integrated with the hardware.
          </p>
          <p style={{lineHeight: '30px', textAlign: 'left', marginTop: '25px'}}>
          Signum also has in-house manufacturing capabilities which keep the entire process from design to delivery under one roof.
          </p>
        </Box>
      </CardContent>
    </Card>
  );
};


export default SigAbout;
