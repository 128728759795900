import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SigAbout = (props,{ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          minHeight={400}
          position="relative"
        >
          <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '15px'}}>
            <img src='/static/signum.png' alt='Signum Logo' style={{width: '300px'}}/>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '50px', flexWrap: 'wrap'}}>
          <div style={{marginTop: '10px'}}>
            <b>Signum</b>
            <br/><br/>
            <p>112 N University Dr. Suite 130</p>
            <p>Fargo, ND 58102</p>
            <p>Phone: 701.730.3806</p>
            <p>Email: info@signum.com</p>
            {/* <a href='https://qedembedded.com/' style={{textDecoration: 'underline'}}>https://qedembedded.com/</a> */}
          </div>
          <div style={{marginTop: '10px'}}>
            <b>Hours of Operation</b>
            <br/><br/>
            <p>Mon-Fri: 8am – 5pm</p>
            <p>Saturday: Closed</p>
            <p>Sunday: Closed</p>
          </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
            <img src='/static/qed-map.png' alt='Google Map' style={{width: '70%', maxWidth: '900px'}}/>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};


export default SigAbout;
