import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';


function* getGFData(action) {
  yield put({
    type: 'IS_LOADING',
    payload: true
  });
  try {
    //Temp function for 3 devices
    const response = yield axios.get(`/api/grandFarm/${action.payload}`);
    let newArray = [response.data[0], response.data[1], response.data[2]]
    let oldArray = [response.data[3], response.data[4], response.data[5]]
    //console.log(newArray, oldArray)
    let newTemp = 0
    let oldTemp = 0
    let newAir = 0
    let oldAir = 0
    let newVWC = 0
    let oldVWC = 0
    let newEC = 0
    let oldEC = 0
    for (let x of newArray) {
      newTemp = newTemp + x.sdi1.temp + x.sdi2.temp
      newVWC = newVWC + x.sdi1.VWC + x.sdi2.VWC
      newEC = newEC + x.sdi1.EC + x.sdi2.EC
      newAir = newAir + x.temp + x.temp
    }
    for (let x of oldArray) {
      oldTemp = oldTemp + x.sdi1.temp + x.sdi2.temp
      oldVWC = oldVWC + x.sdi1.VWC + x.sdi2.VWC
      oldEC = oldEC + x.sdi1.EC + x.sdi2.EC
      oldAir = oldAir + x.temp + x.temp
    }
    newTemp = newTemp / 6
    oldTemp = oldTemp / 6
    newVWC = newVWC / 6
    oldVWC = oldVWC / 6
    newEC = newEC / 6
    oldEC = oldEC / 6
    newAir = newAir / 6
    oldAir = oldAir / 6

    const allData = {
      soilTemp: [newTemp, oldTemp],
      airTemp: [newAir, oldAir],
      vwc: [newVWC, oldVWC],
      ec: [newEC, oldEC],
      allData: response
    }
    yield put({
      type: 'ALL_DATA',
      payload: allData
    });
    let colorArray = [
      "#0d5ac1",
      "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
      "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
      "#9348af", "#01ac53", "#c5a4fb", "#4bb473", "#75d89e",
      "#2f3f94", "#2f7b99", "#34891f", "#b0d87b", "#7e50a8",
      "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
      "#513d98", "#4b5bdc", "#0cd36d",
      "#250662", "#cb5bea", "#228916", "#539397", "#880977",
      "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
      "#5be4f0", "#57c4d8", "#a4d17a", "#96b00c", "#088baf",
      "#05d371", "#5426e0", "#4834d0", "#802234",
      "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
      "#fb21a3", "#51aed9", "#5bb32d", "#21538e", "#89d534", "#d36647",
      "#7fb411", "#0023b8", "#3b8c2a", "#983f7a", "#ea24a3",
      "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
      "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
      "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
      "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
      "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
      "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
      "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
      "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
      "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
      "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
      "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
      "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
      "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
      "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
      "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
      "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
      "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
      "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
      "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
      "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
      "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
      "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
      "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
      "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
      "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
      "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"]
    let dataToUse = [];
    let devices = {};
    response.data.forEach(element => {
      if (element.deviceId) {
        let key = element.deviceId;
        if (!devices[key]) {
          devices[key] = [];
        }
        devices[key].push(element);

      }
      else {
        dataToUse.push(element);
      }
    })
    // console.log(dataToUse);
    // console.log(devices);
    let newChartData = [];
    let outsideData = []
    let VWCData = [];
    let ECData = [];
    let batteryChart = [];
    let deviceData = [];
    let soilTemp = []
    let soilTemp1 = []
    let soilData = []

    let avgSdiTemp = {};
    let avgSdiVWC = {};
    let avgSdiEC = {};

    let airTemp = {};
    let batteryData = {};

    let sdi1Temp = {};
    let sdi2Temp = {};
    let sdi1VWC = {};
    let sdi2VWC = {};
    let sdi1EC = {};
    let sdi2EC = {};

    let keys = Object.keys(devices);
    var i = 0;
    var e = 0;
    var x = 0;
    var z = 0;
    var q = 0;
    var w = 0;
    var r = 0;
    var t = 0;
    var a = 0
    var s = 0
    keys.forEach(key => {
      var deviceMap = {
        '9157c2c1-0318-4f9b-bb7f-e28275af7e8e': 'Upper Slope',
        'e80d5d46-5d6d-4bb3-9cb7-6d4dfda4305c': 'Mid Slope',
        '806d0df2-bb3e-4f80-b408-9b45ad4fa619': 'Entrance',
      }
      avgSdiTemp = {
        label: deviceMap[key] !== undefined ? deviceMap[key] : key,
        fill: false,
        borderColor: colorArray[e],
        backgroundColor: colorArray[e++],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      avgSdiVWC = {
        label: deviceMap[key] !== undefined ? deviceMap[key] : key,
        fill: false,
        borderColor: colorArray[x],
        backgroundColor: colorArray[x++],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      avgSdiEC = {
        label: deviceMap[key] !== undefined ? deviceMap[key] : key,
        fill: false,
        borderColor: colorArray[z],
        backgroundColor: colorArray[z++],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }


      airTemp = {
        label: "Temp Sensor",
        fill: false,
        borderColor: colorArray[1],
        backgroundColor: colorArray[1],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      batteryData = {
        label: deviceMap[key] !== undefined ? deviceMap[key] : key,
        backgroundColor: colorArray[2],
        borderColor: colorArray[2],
        fill: false,
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      soilData = {
        label: deviceMap[key] !== undefined ? deviceMap[key] : key,
        backgroundColor: colorArray[2],
        borderColor: colorArray[2],
        fill: false,
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,

      }
      sdi1Temp = {
        label: "12″ Sensor ",
        fill: false,
        borderColor: colorArray[1],
        backgroundColor: colorArray[1],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      sdi2Temp = {
        label: "6″ Sensor ",
        fill: false,
        borderColor: colorArray[2],
        backgroundColor: colorArray[2],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      sdi1VWC = {
        label: "12″ Sensor ",
        fill: false,
        borderColor: colorArray[1],
        backgroundColor: colorArray[1],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      sdi2VWC = {
        label: "6″ Sensor ",
        fill: false,
        borderColor: colorArray[2],
        backgroundColor: colorArray[2],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      sdi1EC = {
        label: "12″ Sensor ",
        fill: false,
        borderColor: colorArray[1],
        backgroundColor: colorArray[1],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }
      sdi2EC = {
        label: "6″ Sensor ",
        fill: false,
        borderColor: colorArray[2],
        backgroundColor: colorArray[2],
        data: [],
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      }

      devices[key].forEach(datum => {

        let soilTemp = (Number(datum.sdi2.temp) + Number(datum.sdi1.temp)) / 2
        let VWC = (Number(datum.sdi1.VWC) + Number(datum.sdi2.VWC)) / 2
        let EC = (Number(datum.sdi1.EC) + Number(datum.sdi2.EC)) / 2
        avgSdiTemp.data.push({ x: new Date(Date.parse(datum.time)), y: soilTemp });
        avgSdiVWC.data.push({ x: new Date(Date.parse(datum.time)), y: VWC });
        avgSdiEC.data.push({ x: new Date(Date.parse(datum.time)), y: EC });
        if (deviceMap[datum.deviceId] == 'Mid Slope') {
          sdi1Temp.data.push({ x: new Date(Date.parse(datum.time)), y: (Number(datum.sdi2.temp) * 1.8) + 32 });
          sdi2Temp.data.push({ x: new Date(Date.parse(datum.time)), y: (Number(datum.sdi1.temp) * 1.8) + 32 });
          sdi1VWC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi2.VWC) });
          sdi2VWC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi1.VWC) });
          sdi1EC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi2.EC) });
          sdi2EC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi1.EC) });
        }
        else {
          sdi1Temp.data.push({ x: new Date(Date.parse(datum.time)), y: (Number(datum.sdi1.temp) * 1.8) + 32 });
          sdi2Temp.data.push({ x: new Date(Date.parse(datum.time)), y: (Number(datum.sdi2.temp) * 1.8) + 32 });
          sdi1VWC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi1.VWC) });
          sdi2VWC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi2.VWC) });
          sdi1EC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi1.EC) });
          sdi2EC.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.sdi2.EC) });
        }
        airTemp.data.push({ x: new Date(Date.parse(datum.time)), y: (Number(datum.temp) * 1.8) + 32 });
        batteryData.data.push({ x: new Date(Date.parse(datum.time)), y: Number(datum.batt).toFixed(2) });
      })

      let name = deviceMap[key]
      let object = {
        name: name,
        airTemp: { datasets: [airTemp] },
        soilTemp: { datasets: [sdi1Temp, sdi2Temp] },
        VWC: { datasets: [sdi1VWC, sdi2VWC] },
        EC: { datasets: [sdi1EC, sdi2EC] },
      }
      soilTemp.push(sdi1Temp)
      soilTemp1.push(sdi2Temp)
      newChartData.push(airTemp);
      outsideData.push(avgSdiTemp);
      VWCData.push(avgSdiVWC);
      ECData.push(avgSdiEC)
      batteryChart.push(batteryData);
      deviceData.push(object)
    })
    let average = {
      name: 'Average',
      airTemp: newAir,
      soilTemp: newTemp,
      VWC: newVWC,
      EC: newEC
    }
    soilData.data = soilTemp
    let data = {
      tempData: {
        label: 'Temps',
        Type: 'line',
        datasets: newChartData
      },
      deviceData,
      soilTempData: {
        label: 'Soil Temps',
        type: 'line',
        datasets: outsideData
      },
      vmcData: {
        label: 'VWC',
        datasets: VWCData
      },
      ecData: {
        datasets: ECData
      },
      // batteryData: {
      //   label: 'Battery Levels',
      //   type: 'line',
      //   datasets: batteryChart
      // }
    }
    yield put({
      type: 'DEVICE',
      payload: deviceData
    });
    yield put({
      type: 'SET_DATA',
      payload: data
    });
    yield put({
      type: 'IS_LOADING',
      payload: false
    });
  } catch (error) {
    console.log('Error with getting all device data', error);
  }

}


function* GFSaga() {
  yield takeLatest('GET_DATA', getGFData);
  // yield takeLatest('GET_ALL_DATA', getAllData)
}

export default GFSaga;
