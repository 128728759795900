import React from 'react';

const GFLogo = (props) => {
  return (
    <img
      alt="Logo"
      height="20%"
      width="80%"
      src="/static/Grand-Farm.png"
      {...props}
    />
  );
};

export default GFLogo;
